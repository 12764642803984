import React from "react"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Section from "@components/section"
import Container from "@components/container"
import StaticHero from "@components/staticHero"
import { heading1 } from "@styles/ui"
import PrimaryCta from "@components/PrimaryCta"

// Assets
import forAdults from "@images/xywav-side-effects/for-adults.svg"
import forChildren from "@images/xywav-side-effects/for-children.svg"

const sideEffects = [
  {
    header: "For adults,",
    subHeader: "the most common side effects are:",
    image: forAdults,
    list: [
      "Headache",
      "Nausea",
      "Dizziness",
      "Decreased appetite",
      "Parasomnia",
      "Diarrhea",
      "Excessive sweating",
      "Anxiety",
      "Vomiting",
    ],
  },
  {
    header: "For children,",
    subHeader: "the most common side effects* include:",
    image: forChildren,
    list: [
      "Nausea",
      "Bedwetting",
      "Vomiting",
      "Headache",
      "Weight decrease",
      "Decreased appetite",
      "Dizziness",
      "Sleepwalking",
    ],
    footnote: "*Of XYREM (which contains oxybate like XYWAV).",
  },
]

const XywavSideEffectsPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="XYWAV Medication & Possible Side Effects | XYWAV®"
        description="Understand the possible side effects of XYWAV® (calcium, magnesium, potassium, and sodium oxybates) for your narcolepsy treatment. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      {/* Static hero */}
      <StaticHero eyebrow="Side effects" gradient="purple-red">
        Understanding the common side effects of
        <br tw="hidden lg:block" /> treatment with XYWAV
      </StaticHero>

      <Section>
        <Container grid>
          <div tw="col-span-full">
            <div tw="before:(gradientRed block relative w-[100px] mb-[30px] h-1.5 rounded-md) lg:before:(mb-[25px] w-[135px])"></div>
            <h2
              css={[
                heading1,
                tw`text-[22px] leading-[28px] text-merlin mb-[30px] pb-0 lg:(text-[25px] leading-[30px] mb-[25px])`,
              ]}
            >
              XYWAV can cause serious side effects, including the following:
            </h2>
          </div>
          <div tw="col-span-full mb-[30px] lg:(col-span-6 mb-0)">
            <div tw="mb-[30px] lg:mb-[25px]">
              <h3 tw="font-black text-[20px] leading-normal mb-4 lg:(text-[22px] leading-[125%] mb-2.5)">
                Breathing problems
              </h3>
              <p tw="text-base mb-2.5">
                Including slower breathing, trouble breathing, and/or short
                periods of not breathing while sleeping (sleep apnea).
              </p>
              <p tw="text-base">
                People who already have breathing or lung problems have a higher
                chance of having breathing problems when they use XYWAV.
              </p>
            </div>
            <div>
              <h3 tw="font-black text-[20px] leading-normal mb-4 lg:(text-[22px] leading-[125%] mb-2.5)">
                Sleepwalking
              </h3>
              <p tw="text-base">
                XYWAV can cause sleepwalking, which can cause injuries. Call
                your doctor if this occurs.
              </p>
            </div>
          </div>
          <div tw="col-span-full lg:col-span-6">
            <div tw="lg:max-w-[38.5rem]">
              <h3 tw="font-black text-[20px] leading-normal mb-4 lg:(text-[22px] leading-[125%] mb-2.5)">
                Mental health problems
              </h3>
              <p tw="text-base mb-2.5">
                Including confusion, seeing or hearing things that are not real
                (hallucinations), unusual or disturbing thoughts (abnormal
                thinking), feeling anxious or upset, depression, thoughts of
                killing yourself or trying to kill yourself, increased
                tiredness, feelings of guilt or worthlessness, or difficulty
                concentrating.
              </p>
              <p tw="text-base">
                Tell your doctor if you or your child have or had depression or
                have tried to harm yourself or themselves.{" "}
                <strong tw="font-black">
                  Call your doctor right away if you have or your child has
                  symptoms of mental health problems or a change in weight or
                  appetite.
                </strong>
              </p>
            </div>
          </div>
        </Container>
      </Section>

      <Section bgColour={tw`bg-lilac`} tw="py-[15px] lg:(pt-[25px] pb-[15px])">
        <Container grid>
          {sideEffects.map((item, index) => (
            <div
              key={`side-effects-${index}`}
              css={[
                tw`col-span-full text-center lg:col-span-6`,
                index < sideEffects.length - 1 && tw`mb-[30px] lg:mb-0`,
              ]}
            >
              <img
                src={item.image}
                tw="w-[88px] mx-auto mb-[26px] lg:(w-[98px] mb-[50px])"
                alt=""
              />
              <h2
                css={[
                  heading1,
                  tw`text-[22px] leading-[28px] text-merlin mb-[15px] pb-0 lg:(text-[25px] leading-[30px] mb-[25px])`,
                ]}
              >
                {item.header}
                <span tw="block font-avenir font-black text-[20px] leading-normal mb-4 lg:(text-[22px] leading-[125%] mb-2.5)">
                  {item.subHeader}
                </span>
              </h2>
              <ul
                css={[
                  tw`flex flex-col flex-wrap gap-x-[25px] gap-y-2.5 list-disc px-4 max-w-[370px] mx-auto lg:(px-8 max-w-[464px])`,
                  index === 0
                    ? tw`h-[165px] lg:h-[145px]`
                    : tw`h-[140px] lg:h-[120px]`,
                ]}
              >
                {item.list.map((sideEffect, sideEffectIndex) => (
                  <li
                    key={`side-effect-${index}-${sideEffectIndex}`}
                    tw="w-[calc(50% - 32px)] text-left ml-4"
                  >
                    {sideEffect}
                  </li>
                ))}
              </ul>
              {item?.footnote && (
                <p tw="text-[12px] mt-[15px] lg:mt-[25px]">{item.footnote}</p>
              )}
            </div>
          ))}
        </Container>
      </Section>

      <Section bgColour={tw`lilac-gradient-vertical`}>
        <Container grid>
          <div tw="col-span-full text-center lg:(col-start-2 col-span-10)">
            <h2 tw="font-black text-[20px] leading-normal mb-4 lg:(text-[22px] leading-[125%] mb-6)">
              In patients who remained on treatment, side effects tended to
              occur early and diminish over&nbsp;time
            </h2>
            <p tw="text-base lg:(text-[18px] leading-[140%])">
              Overall, adverse reactions related to XYWAV were reported less
              frequently in patients who were taking
              <br tw="hidden xl:block" /> XYREM<sup>®</sup> (sodium oxybate)
              oral solution when they entered the study, compared to patients
              who weren&apos;t taking XYREM.
            </p>
          </div>
        </Container>
      </Section>

      <Section>
        <Container grid>
          <div tw="col-span-full mb-[15px] lg:(col-span-6 mb-0)">
            <div tw="before:(gradientRed block relative w-[100px] mb-[15px] h-1.5 rounded-md) lg:before:(mb-[25px] w-[135px])"></div>
            <h2
              css={[
                heading1,
                tw`text-[22px] leading-[28px] text-merlin pb-0 lg:(text-[25px] leading-[30px])`,
              ]}
            >
              There are other possible side effects
            </h2>
          </div>
          <div tw="col-span-full lg:(col-span-6 flex items-center)">
            <p tw="text-base">
              For more information, ask your doctor or pharmacist. Call your
              doctor for medical advice about side effects. You are encouraged
              to report any negative side effects to the FDA at{" "}
              <a href="tel:1-800-FDA-1088" tw="underline text-studio">
                1-800-FDA-1088
              </a>
              .
            </p>
          </div>
        </Container>
      </Section>

      <Section bgColour={tw`bg-lilac`}>
        <Container addedStyles={[tw`text-center`]}>
          <div tw="before:(gradientRed block relative w-[100px] mb-[15px] h-1.5 rounded-md mx-auto) xl:before:(mb-6 w-[135px])"></div>
          <h2
            css={[
              heading1,
              tw`text-[22px] leading-[28px] text-merlin mb-[25px] pb-0 lg:(text-[25px] leading-[30px])`,
            ]}
          >
            Already prescribed XYWAV?
          </h2>
          <p tw="mb-[1.5625rem] text-base">
            Watch a JazzCares<sup>®</sup> Nurse discuss the services available
            to support you from the beginning of your treatment and every step
            of the way.
          </p>
          <PrimaryCta
            url={"/narcolepsy/getting-xywav/#jazzcares-videos"}
            noInterstitial
            mdSmall
            width={tw`max-w-[335px] md:max-w-[335px]`}
          >
            WATCH GETTING STARTED VIDEOS
          </PrimaryCta>
        </Container>
      </Section>
    </Layout>
  )
}

export default XywavSideEffectsPage
